<template>
  <div class="container-fluid">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SALES_ORDERS)"
          :to="{ name: 'List SalesOrders' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-bag-17"></i>
            </span>
            <small>{{ $t("COMMON.SALES_ORDERS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SALES_INVOICES)"
          :to="{ name: 'List SalesInvoices' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-single-copy-04"></i>
            </span>
            <small>{{ $t("COMMON.SALES_INVOICES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SALES_PAYMENTS)"
          :to="{ name: 'List SalesPayments' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-2x ni-money-coins"></i>
            </span>
            <small>{{ $t("COMMON.SALES_PAYMENTS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SALES_DELIVERIES)"
          :to="{ name: 'List SalesDeliveries' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fa fa-2x fa-truck"></i>
            </span>
            <small>{{ $t("COMMON.SALES_DELIVERIES") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
